import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Grid } from '@material-ui/core'
import { Link } from 'gatsby'
import Img1 from '../images/avocat-coentreprise-affaires.jpg'
import { graphql } from 'gatsby'

const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}
export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

const renderDate = (dateString) =>
    new Date(dateString).toLocaleString('fr-CA', options)

const Main = styled.main`
    a {
        color: black;
        &:visited {
            color: black;
        }
    }
    p {
        height: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .gatsby-image-wrapper {
        margin: 7px auto;
    }
`

const Blog = ({ location }) => {
    return (
        <Layout location={location}>
            <h1>Blog - Soumissions Avocat</h1>
            <Main>
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-coentreprise/">
                            <h2>
                                Comment former une coentreprise SOLIDE? Demandez
                                conseil aux avocats en droit des affaires!
                            </h2>
                            <span>
                                {renderDate('2021-08-06T18:57:35+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-coentreprise-affaires.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Les plus grandes réussites d’affaires sont bien
                                souvent les histoires d’alliances. C’est pour
                                cette raison que la coentreprise se présente
                                comme une option extrêmement intéressante pour
                                bien des entrepreneurs souhaitant s’associer
                                avec d’autres sommités de leur domaine afin de
                                combiner leurs forces respectives. Mais faites
                                bien ATTENTION – Cette association ne doit pas
                                se faire aux dépens de votre propre entreprise!
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/quoi-faire-accusation-criminelle/">
                            <h2>
                                Quoi faire lors d’une accusation criminelle?
                                Contactez un avocat sans délai pour vous
                                défendre!
                            </h2>
                            <span>
                                {renderDate('2021-08-05T20:09:43+00:00')}
                            </span>
                            <StaticImage
                                src="../images/quoi-faire-accusation-criminelle-768x432.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                On vient de déposer une accusation criminelle
                                contre vous? Quel que soit le chef d’accusation,
                                l’heure est grave et il est temps d’agir! Les
                                conséquences d’un verdict de culpabilité rendu à
                                votre endroit peuvent être très lourdes, alors
                                assurez-vous de bénéficier d’une défense pleine
                                et entière en consultant un avocat criminaliste
                                d’expérience!
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/bail-commercial/">
                            <h2>
                                Trouvez un avocat pour rédiger un bail
                                commercial! Bail brut vs. Bail net!
                            </h2>
                            <span>
                                {renderDate('2021-07-27T02:35:58+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-bail-commercial-quebec-1024x683.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Pour bien des entreprises québécoises, la
                                location d’un local commercial fait partie
                                intégrante de leurs activités d’affaires. Rares
                                sont les PME et même les grandes entreprises qui
                                ont les moyens financiers d’acheter leur propre
                                centre d’affaires, et c’est là que la location
                                d’un espace commercial entre en jeu! Vous
                                l’aurez deviné, qui dit location d’espace dit
                                également signature d’un bail!
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-pme-specialise/">
                            <h2>
                                Propriétaire d’une PME? Choisissez un avocat
                                spécialisé!
                            </h2>
                            <span>
                                {renderDate('2021-07-21T20:57:05+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-pme-affaires-quebec-768x512.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vous êtes propriétaire d’une PME québécoise et
                                vous tentez de cerner vos besoins juridiques?
                                Vous êtes loin d’être seul! De nombreux
                                actionnaires et propriétaires de PME ont du mal
                                à identifier leurs besoins en matière de
                                services d’avocats, mais sachez que cette
                                expertise légale contribue grandement à faire
                                croitre le rendement de votre compagnie. De la
                                phase de démarrage jusqu’à la vente de
                                l’entreprise en passant par son incorporation,
                                l’avocat en droit des affaires spécialisé dans
                                le monde des PME est un atout essentiel!
                                Celui-ci vous assure non seulement une
                                protection optimale du point de vue légal, mais
                                également des conseils adaptés à la réalité des
                                PME du Québec.
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/contrats-formation-annulation/">
                            <h2>
                                Tous les types de contrats au Québec :
                                formation, annulation ou résiliation avec un
                                avocat!
                            </h2>
                            <span>
                                {renderDate('2021-05-18T16:35:56+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-annulation-formation-contrat-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Le droit des contrats fait partie de votre
                                réalité quotidienne sans même que vous ne le
                                sachiez! En effet, pas une journée ne passe sans
                                que vous ne concluiez un contrat avec une autre
                                personne, que ce soit en achetant des vêtements,
                                en mettant de l’essence dans votre véhicule ou
                                en concluant une entente d’affaires. Et
                                pourtant, vous ne connaissez même pas les bases
                                du droit des contrats au Québec?{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-accusation-vol/">
                            <h2>
                                Comment vous débarrasser d’une accusation de vol
                                au Québec? Vol à l’étalage et vol qualifié!
                            </h2>
                            <span>
                                {renderDate('2021-04-16T15:17:17+00:00')}
                            </span>
                            <StaticImage
                                src="../images/comment-debarrasser-accusation-vol-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                L’infraction de vol apparaît parmi les crimes
                                les plus fréquemment rapportés par la police au
                                Canada. Comme il s’agit d’une infraction dont la
                                définition est très large, un grand nombre de
                                comportements interdits peuvent se retrouver
                                sous ce chef d’accusation. Ce pourrait bien être
                                votre cas si vous éprouvez des démêlés avec la
                                justice!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-criminel-voies-de-fait/">
                            <h2>
                                Êtes-vous accusé de voies de fait? Voici la
                                méthode pour vous défendre avec un avocat!
                            </h2>
                            <span>
                                {renderDate('2021-04-14T16:03:24+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-accusation-voies-de-fait-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Quelle que soit leur gravité, les voies de fait
                                figurent parmi les infractions les plus
                                sérieuses du Code criminel. Comme des séquelles
                                parfois indélébiles sont laissées sur les
                                victimes, la loi prévoit des sanctions sévères à
                                l’endroit des individus accusés de voies de
                                fait, allant même jusqu’à l’emprisonnement
                                pendant 14 ans! Devant de telles accusations,
                                l’heure presse pour préparer une défense.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/accusation-possession-trafic-drogue/">
                            <h2>
                                Accusations de possession et trafic de drogues :
                                trouvez un avocat en droit criminel RAPIDEMENT!
                            </h2>
                            <span>
                                {renderDate('2021-04-09T16:19:18+00:00')}
                            </span>
                            <StaticImage
                                src="../images/accusation-criminelle-possession-drogues-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                La consommation de cannabis a peut-être été
                                légalisée en 2018, mais les crimes reliés aux
                                autres drogues demeurent bien présents! Qu’il
                                s’agisse de possession de stupéfiants, de trafic
                                ou d’exportation, les conséquences de ces crimes
                                sont graves et laissent des marques indélébiles
                                sur le casier judiciaire d’une personne reconnue
                                coupable.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-assurances/">
                            <h2>
                                Quoi faire si votre assureur refuse de vous
                                indemniser? Tournez-vous vers l’avocat en droit
                                des assurances!
                            </h2>
                            <span>
                                {renderDate('2021-04-09T15:58:31+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-assurance-indemnite-recours-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                L’assurance fait partie de la vie de tout bon
                                citoyen prévoyant et diligent. De l’assurance de
                                dommages à l’assurance vie en passant par les
                                diverses couvertures spécifiques, ces
                                protections sont indispensables pour protéger
                                vos intérêts personnels et financiers. Mais
                                qu’arrive-t-il lorsqu’un assureur refuse
                                d’honorer une police d’assurance suite à un
                                sinistre?{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-syndic-faillite-insolvabilite/">
                            <h2>
                                Avocat en droit de la faillite vs. Syndic de
                                faillite : différences, avantages et conseils!
                            </h2>
                            <span>
                                {renderDate('2021-03-23T01:31:06+00:00')}
                            </span>
                            <StaticImage
                                src="../images/faillite-avocat-vs-syndic-avantages.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vos problèmes d’insolvabilité s’aggravent
                                lentement, mais surement? La négociation avec
                                vos créanciers a échoué et le moment de faire
                                face à la musique approche à grands pas? Dans ce
                                cas, ne restez pas les bras croisés à attendre
                                votre sentence, agissez dès maintenant auprès
                                d’un expert en faillite et insolvabilité au
                                Québec!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/convention-collective-syndicat/">
                            <h2>
                                Avocat en droit du travail pour la négociation
                                de convention collective, accréditation et mise
                                en place d’un syndicat !
                            </h2>
                            <span>
                                {renderDate('2021-03-11T21:11:57+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-travail-syndicat-convention.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Les syndicats font partie intégrante de la
                                sphère du travail au Québec. Dans la fonction
                                publique comme privée, nombreux sont les milieux
                                de travail où les relations entre l’employeur et
                                ses salariés sont encadrées par des syndicats.
                                Que vous soyez du côté employeur ou employé, la
                                syndicalisation affectera grandement votre
                                réalité quotidienne au travail, en plus d’amener
                                son lot de droits et d’obligations pour toutes
                                les personnes impliquées!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/planification-releve-entreprise/">
                            <h2>
                                Planifier sa relève d’entreprise avec un avocat,
                                une démarche en plusieurs étapes!
                            </h2>
                            <span>
                                {renderDate('2021-03-05T14:34:28+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-releve-entreprise-planification-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vous avez passé des années à bâtir une
                                entreprise rentable et durable; il va de soi que
                                la relève de celle-ci ne se formera pas en un
                                jour! Passer le flambeau peut sembler comme une
                                considération secondaire lorsque vous avez
                                vous-même bâti une compagnie à partir de rien,
                                mais détrompez-vous. Vous êtes à deux pas du fil
                                d’arrivée, mais il reste encore plusieurs étapes
                                à accomplir pour assurer une relève d’entreprise
                                solide et profitable.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/structure-entreprise/">
                            <h2>
                                Structure d’entreprise : comment choisir entre
                                la société en nom collectif, la société en
                                commandite et la société par actions?
                            </h2>
                            <span>
                                {renderDate('2021-03-05T14:15:29+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-structure-entreprise-avantages-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                La structure d’une entreprise et sa rentabilité
                                sont directement reliées. Bien plus qu’une
                                simple formalité administrative, la structure
                                juridique de l’entreprise peut avoir un impact
                                direct et considérable sur les revenus de cette
                                dernière, surtout d’un point de vue fiscal! Et
                                pourtant, bon nombre d’entrepreneurs ne
                                connaissent que deux options, soit
                                l’incorporation et l’entreprise individuelle.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/conflit-actionnaires-entreprise/">
                            <h2>
                                Comment régler un conflit entre actionnaires
                                d’une entreprise? Consultez un avocat sans
                                tarder!
                            </h2>
                            <span>
                                {renderDate('2021-03-05T14:01:41+00:00')}
                            </span>
                            <StaticImage
                                src="../images/conflit-actionnaires-litige-avocat-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Même les entreprises les plus prolifiques et
                                prospères ne sont pas à l’abri des disputes
                                entre ses têtes dirigeantes. Les conflits
                                d’actionnaires peuvent affecter n’importe quel
                                projet d’affaires, mettant les intérêts
                                financiers de la compagnie et de ses
                                investisseurs en péril. Il est essentiel d’agir
                                rapidement lorsqu’un litige entre actionnaires
                                se pointe à l’horizon pour préserver les
                                intérêts de la société!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/droit-auteur-marque-commerce/">
                            <h2>
                                Avocat en droit de la propriété intellectuelle :
                                enregistrement de marques de commerce, droits
                                d’auteur et brevets!
                            </h2>
                            <span>
                                {renderDate('2021-03-05T13:34:58+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-propriete-intellectuelle-services-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Le secteur de la propriété intellectuelle a
                                connu une croissance effrénée depuis la
                                popularisation du web et celle-ci se maintient à
                                ce jour! Le nombre d’entreprises exposant leurs
                                services, leurs marques et leur personnalité sur
                                la toile a pour effet de générer une clientèle
                                internationale, mais également d’exposer des
                                idées originales au monde entier!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/requete-cour-petites-creances/">
                            <h2>
                                Requête à la Cour des petites créances :
                                pouvez-vous être assisté d’un avocat?
                            </h2>
                            <span>
                                {renderDate('2021-02-26T19:28:32+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-cour-petites-creances-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Les bras de la justice sont longs et nul n’est à
                                l’abri des poursuites judiciaires. Que ce soit
                                dans le cadre de vos affaires personnelles ou
                                d’entreprise, il arrive que des conflits
                                menacent de se transformer en litige. Devant une
                                telle situation, vous vous retrouverez face à un
                                dilemme : vaut-il vraiment la peine de régler le
                                désaccord devant les tribunaux? L’investissement
                                de temps et d’argent en vaut-il la chandelle?{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/clause-non-concurrence-commerciale/">
                            <h2>
                                La clause de non-concurrence commerciale :
                                quelles sont ses limites? Demandez conseil aux
                                avocats en droit des affaires!
                            </h2>
                            <span>
                                {renderDate('2021-02-12T15:14:07+00:00')}
                            </span>
                            <StaticImage
                                src="../images/clause-non-concurrence-commerciale-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                En affaires comme à la guerre, il arrive que des
                                soldats changent de camp. Après tout, certaines
                                associations ne sont pas faites pour durer
                                éternellement et il est normal que des
                                partenaires d’affaires se séparent. Ceci dit, la
                                séparation et le départ d’un actionnaire ne
                                devraient jamais se faire au détriment des
                                intérêts financiers de l’entreprise!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/garanties-achat-maison/">
                            <h2>
                                Toutes les garanties qui viennent avec l’achat
                                d’une maison : à quoi s’engage le vendeur
                                lorsqu’il vous vend sa maison?
                            </h2>
                            <span>
                                {renderDate('2021-01-08T17:58:59+00:00')}
                            </span>
                            <StaticImage
                                src="../images/garanties-achat-maison-avocat.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vous êtes récemment devenu propriétaire d’une
                                maison? Toutes nos félicitations pour votre
                                statut de propriétaire immobilier! Avec un tel
                                statut viennent évidemment de nouvelles
                                obligations, mais également des droits, dont
                                ceux de garantie prévus par la loi. Notamment,
                                la garantie légale de qualité et la garantie du
                                droit de propriété jouent désormais en votre
                                faveur, mais connaissez-vous réellement leur
                                étendue?{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-crimes-sexuels/">
                            <h2>
                                Les impacts d’une condamnation pour crimes
                                sexuels : défendez-vous si vous n’êtes pas
                                coupable!
                            </h2>
                            <span>
                                {renderDate('2020-11-26T21:19:13+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-defense-crimes-sexuels-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Les crimes sexuels sont des actes à haut
                                stigmate dans la société et avec raison. Les
                                séquelles éprouvées par les victimes ne
                                sauraient être réparées et leur seul espoir
                                d’obtenir réparation est de laisser la justice
                                suivre son cours. Toutefois, la justice étant
                                une route à deux sens, aussi bien l’accusé que
                                la victime dispose du droit défendre sa version
                                des faits.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-conflit-partenaire-affaires/">
                            <h2>
                                Je veux me débarrasser de mon partenaire
                                d’affaires, comment faire?
                            </h2>
                            <span>
                                {renderDate('2020-11-26T21:06:00+00:00')}
                            </span>
                            <StaticImage
                                src="../images/comment-debarrasser-partenaire-affaires-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Tout partenariat commercial commence avec les
                                bonnes intentions de part et d’autre. Cependant,
                                les affaires étant les affaires, il arrive que
                                la collaboration ne tienne plus la route et que
                                vous souhaitiez ainsi mettre un terme à la
                                relation avec votre partenaire. Pas si vite, vos
                                associés disposent eux aussi de certains droits
                                qu’il vous incombe de respecter lors du
                                « divorce ».{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-adoption/">
                            <h2>
                                Comment fonctionne le processus d’adoption au
                                Québec? Entamez la démarche avec un avocat!
                            </h2>
                            <span>
                                {renderDate('2020-11-20T18:15:51+00:00')}
                            </span>
                            <StaticImage
                                src="../images/etapes-dossier-adoption-quebec-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Adopter un enfant représente une démarche
                                remplie d’humanité et de vertu. Qu’à cela ne
                                tienne, c’est également un processus fastidieux,
                                complexe et nécessitant de côtoyer la sphère
                                juridique de près.  Il est donc essentiel de
                                s’aventurer dans la démarche d’adoption avec le
                                bon allié à vos côtés, soit l’avocat en droit de
                                la famille!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-responsabilite-medicale/">
                            <h2>
                                Responsabilité médicale : quelles conditions
                                pour être dédommagé suite à une faute d’un
                                professionnel de la santé?
                            </h2>
                            <span>
                                {renderDate('2020-11-05T22:12:48+00:00')}
                            </span>
                            <StaticImage
                                src="../images/poursuite-responsabilite-medicale-medecin-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Les médecins sont des professionnels tenus en
                                haute estime dans la société québécoise, mais
                                malgré tout, ils ne sont pas parfaits. L’erreur
                                étant humaine, les médecins le sont également et
                                il est possible qu’ils commettent une faute à
                                votre endroit vous causant un préjudice
                                indemnisable. Dans de telles circonstances, de
                                quels recours disposez-vous pour obtenir
                                réparation?{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/clause-limitation-responsabilite/">
                            <h2>
                                Les clauses d’exonération de responsabilité : le
                                « waiver » que vous avez signé vaut-il quelque
                                chose?
                            </h2>
                            <span>
                                {renderDate('2020-10-30T14:19:51+00:00')}
                            </span>
                            <StaticImage
                                src="../images/clause-limitation-responsabilite-avocat.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Quiconque a déjà participé à une activité
                                moindrement dangereuse a été forcé de signer une
                                décharge de responsabilité. Qu’il s’agisse d’une
                                activité de ski, de parachute, de karting ou
                                autre « sport » extrême, la majorité, pour ne
                                pas dire la totalité des entreprises offrant de
                                telles activités tente de se protéger contre les
                                poursuites en faisant signer une clause
                                limitative de responsabilité aux participants.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-prescription-acquisitive/">
                            <h2>
                                Qu’est-ce que la prescription acquisitive et
                                comment s’en prévaloir? Obtenez conseil auprès
                                d’avocats qualifiés!
                            </h2>
                            <span>
                                {renderDate('2020-10-30T14:05:22+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-recours-prescription-acquisitive-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Le mécanisme de la prescription acquisitive est
                                possiblement le plus surprenant et méconnu du
                                droit. Par l’entremise de la possession et de
                                l’écoulement du temps, la propriété d’un tiers
                                peut devenir vôtre. Incroyable n’est pas? Un
                                instant, la prescription acquisitive exige la
                                rencontre de plusieurs critères afin de
                                justifier l’instauration d’un nouveau
                                propriétaire aux dépends d’un autre.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-action-passation-titre/">
                            <h2>
                                Non-respect d’une promesse d’achat : intentez
                                une action en passation de titre auprès d’un
                                avocat en droit immobilier!
                            </h2>
                            <span>
                                {renderDate('2020-10-30T13:42:28+00:00')}
                            </span>
                            <StaticImage
                                src="../images/promesse-achat-action-passation-titre-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                La parole donnée est obligatoire en droit
                                québécois. C’est pourquoi, lorsqu’une personne
                                s’engage à vendre ou acheter une maison par
                                l’entremise d’une promesse d’achat, l’obligation
                                de donner suite à la transaction se cristallise.
                                Advenant qu’une des parties à la promesse
                                souhaite se désister de celle-ci en violation de
                                vos droits, vous n’êtes pas laissé au dépourvu
                                le bec à l’eau : l’action en passation de titre
                                pourrait bien être votre remède juridique de
                                choix.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-servitude/">
                            <h2>
                                Quels sont les services de l’avocat en matière
                                de servitude? Établissement et extinction de
                                servitudes avec un avocat!
                            </h2>
                            <span>
                                {renderDate('2020-10-23T18:51:16+00:00')}
                            </span>
                            <StaticImage
                                src="../images/etablissement-extinction-servitude-avocat-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                La servitude est un droit assez poussiéreux. En
                                effet, cette charge grevée à un immeuble fait
                                partie de la tradition juridique québécoise
                                depuis belle lurette. Toutefois, une forte
                                majorité des propriétaires immobiliers de la
                                province côtoient de près ce droit rattaché à la
                                propriété sans même en connaître les bases.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-poursuite-diffamation/">
                            <h2>
                                On vous diffame? N’attendez pas trop, vous avez
                                un an pour agir!
                            </h2>
                            <span>
                                {renderDate('2020-10-23T18:30:49+00:00')}
                            </span>
                            <StaticImage
                                src="../images/recours-diffamation-avocat-responsabilite-civile.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Les paroles s’envolent peut-être, mais elles
                                causent tout de même des maux parfois
                                irréparables à leur destinataire. L’atteinte à
                                la réputation étant un enjeu de société
                                important, le droit présente une solution
                                efficace, soit le recours diffamation. Depuis
                                l’avènement des réseaux sociaux et de la
                                communication de masse, le potentiel
                                diffamatoire grandit de façon exponentielle.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-vs-notaire-vente-entreprise/">
                            <h2>
                                Avocat VS notaire pour achat ou vente
                                d’entreprise!
                            </h2>
                            <span>
                                {renderDate('2020-09-22T18:06:08+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-vs-notaire-vente-entreprise-scaled.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vendre une entreprise requiert des mois, voire
                                des années de préparation afin de rendre la
                                démarche profitable pour son propriétaire.
                                Liquider une entreprise du jour au lendemain
                                comme s’il s’agissait d’une vente de garage
                                sabotera des années d’efforts à optimiser le
                                rendement de celle-ci. Avant tout, vous devez
                                être entouré des bons experts pour vous
                                conseiller et vous épauler dans cette démarche!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/poursuite-responsabilite-civile-avocat-montreal/">
                            <h2>
                                Poursuite en responsabilité civile engagez un
                                avocat à Montréal pour avoir gain de cause!
                            </h2>
                            <span>
                                {renderDate('2020-04-17T14:52:40+00:00')}
                            </span>
                            <StaticImage
                                src="../images/engager-services-avocat-recours-dommages-responsabilite-civile.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Subir un préjudice, qu’il soit physique,
                                matériel ou moral, laisse des traces parfois
                                indélébiles sur les victimes. Quelle que soit la
                                nature du préjudice subi, lorsqu’il résulte de
                                la faute d’une personne, il y a lieu d’être
                                compensé pour un tel manquement de diligence. Ce
                                principe est d’ailleurs reconnu par le droit
                                québécois et appuyé par ce que l’on appelle la
                                poursuite en responsabilité civile.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/guide-transfert-vente-entreprise-quebec/">
                            <h2>
                                Guide de transfert, vente et d’achat
                                d’entreprise au Québec – Avocat pour entreprise
                            </h2>
                            <span>
                                {renderDate('2020-04-01T16:26:54+00:00')}
                            </span>
                            <StaticImage
                                src="../images/guide-transfert-vente-achat-entreprise-quebec-avocat.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vendre une entreprise après des années de dur
                                labeur n’a rien d’une mince affaire. La
                                préservation du patrimoine, des emplois et de
                                l’intégrité économique de l’entreprise sont les
                                enjeux primordiaux de la démarche de transfert
                                et nécessitent un accompagnement professionnel
                                de pointe. Que vous vous trouviez du côté
                                acheteur ou vendeur de la transaction, les
                                décisions à prendre sont nombreuses et dicteront
                                la profitabilité de la vente une fois celle-ci
                                conclue.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/modification-garde-pension-alimentaire-montreal/">
                            <h2>
                                Modification de garde et pension alimentaire :
                                quel prix pour un avocat en droit de la famille
                                à Montréal?
                            </h2>
                            <span>
                                {renderDate('2020-02-24T08:38:37+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-modification-annulation-droit-garde-pension-alimentaire-montreal.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                La pension alimentaire est un mécanisme légal
                                qui protège les intérêts et le bien-être des
                                enfants lors d’une séparation. Elle est
                                d’ailleurs au cœur de toutes les demandes en
                                divorce dans le but de préserver l’élément phare
                                du processus, soit l’intérêt de l’enfant. C’est
                                donc avec toutes les considérations humaines et
                                financières nécessaires qu’elle est mise en
                                place au juste montant.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/comparer-meilleurs-avocats-immigration-quebec/">
                            <h2>
                                Comparez les meilleurs avocats en immigration au
                                Québec en 2020
                            </h2>
                            <span>
                                {renderDate('2020-02-14T09:02:12+00:00')}
                            </span>
                            <StaticImage
                                src="../images/comparer-meilleurs-avocats-droit-immigration-quebec.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Immigrer au Canada et devenir officiellement
                                citoyen de ce beau grand pays est un rêve chéri
                                par plusieurs et avec raison! Avec la qualité de
                                vie offerte sous l’unifolié, qui ne serait pas
                                prêt à endurer ces hivers ardus n’est-ce pas?
                                Avant de pouvoir croquer à pleines dents dans
                                cette vision, il faut toutefois passer par
                                quelques étapes préalables qui posent leur lot
                                de complexité.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/accusations-conduite-facultees-affaiblies-avocat/">
                            <h2>
                                Conduite avec les facultés affaiblies et alcool
                                au volant : Comparez les Avocats Criminels
                            </h2>
                            <span>
                                {renderDate('2020-02-14T08:39:52+00:00')}
                            </span>
                            <StaticImage
                                src="../images/qui-sont-meilleurs-avocats-criminels-quebec-alcool-volant.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Une accusation pour conduite avec les facultés
                                affaiblies s’avère lourde de conséquences. Il ne
                                suffit que d’un verre de trop pour que votre
                                avenir si bien tracé ne prenne un changement de
                                direction drastique! Avec raison, la loi prévoit
                                des conséquences et sanctions importantes pour
                                quiconque se voit reconnu coupable de conduite
                                avec les facultés affaiblies!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-droit-travail-congediement-quebec/">
                            <h2>
                                Avocat en droit du travail pour congédiement
                                illégal et blessure professionnelle au Québec
                            </h2>
                            <span>
                                {renderDate('2020-02-13T10:33:54+00:00')}
                            </span>
                            <StaticImage
                                src="../images/engager-avocat-droit-travail-congediement-illegal-blessure-professionnelle.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vos nombreuses années de bons et loyaux services
                                ont été récompensées en vous faisant montrer la
                                porte? Votre employeur vous congédie pour un
                                motif discriminatoire qui porte atteinte à vos
                                droits? Vous n’avez pas à sortir du building
                                avec votre boîte en carton bredouille. La loi
                                prévoit des droits pour tous les salariés de la
                                province en termes de congédiement illégal, et
                                un avocat en droit du travail se fera un plaisir
                                de vous aider tout au cours de cette démarche!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-vice-cache-montreal/">
                            <h2>
                                Quel avocat en immobilier engager pour un vice
                                caché à Montréal?
                            </h2>
                            <span>
                                {renderDate('2020-02-13T10:21:02+00:00')}
                            </span>
                            <StaticImage
                                src="../images/quel-avocat-immobilier-enganger-vice-cache-montreal.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Quoi de plus palpitant que d’acheter une maison?
                                Que vous soyez un jeune couple signant l’offre
                                d’achat pour une première maison ou un vétéran
                                des transactions immobilières, l’expérience
                                demeure mémorable. Lorsqu’un vice caché vient se
                                glisser dans la transaction, vous pouvez être
                                sûr qu’elle le sera d’autant plus, mais cette
                                fois pour les mauvaises raisons! Cela n’a
                                toutefois pas à être votre cas en prenant les
                                bonnes précautions.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/prix-avocat-criminel-rive-sud/">
                            <h2>
                                Les avocats en droit criminel sur la Rive-Sud de
                                Montréal
                            </h2>
                            <span>
                                {renderDate('2020-01-30T08:09:57+00:00')}
                            </span>
                            <StaticImage
                                src="../images/rive-sud-montreal-prix-services-avocat-criminel-criminaliste.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Vous avez été arrêté et faites l’objet
                                d’accusations criminelles? Le cœur vous palpite
                                et juste à l’idée de vous imaginer dans un habit
                                orange derrière les barreaux? Le sentiment de
                                panique est comprenable, mais prenez une grande
                                respiration, un avocat en droit criminel sur la
                                Rive-Sud de Montréal peut vous aider à vous en
                                sortir! Quel que soit le type d’accusations
                                auxquelles vous faites face, la solution passe
                                par un avocat criminaliste.
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/demenagement-parent-modification-garde-enfant-avocat/">
                            <h2>
                                Déménagement d’un parent : est-ce que l’enfant
                                suit ou reste? Conseils d’avocat en droit de la
                                famille!
                            </h2>
                            <span>
                                {renderDate('2019-10-30T08:40:00+00:00')}
                            </span>
                            <StaticImage
                                src="../images/demenagement-parent-effet-garde-enfant-modification-avocat.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Avec la mobilité grandissante sur le marché du
                                travail et les réalités familiales de plus en
                                plus complexes, il y a lieu de se demander quel
                                est le sort réservé aux enfants dans ce monde
                                pour le moins tumultueux. Plus pertinent encore,
                                qu’est-ce que le droit a à dire au sujet du
                                déménagement d’un parent dans une autre ville
                                qui souhaite voir son enfant le suivre vers sa
                                nouvelle destination? La réponse n’a rien de
                                simple, vous en conviendrez!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/garde-enfants-erreurs-eviter-separation-avocat/">
                            <h2>
                                Gardes des enfants : les erreurs à éviter au
                                moment de la séparation!
                            </h2>
                            <span>
                                {renderDate('2019-10-30T08:12:55+00:00')}
                            </span>
                            <StaticImage
                                src="../images/garde-enfants-partagee-exclusive-erreurs-eviter-avocat.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Avec le nombre élevé de ruptures et de divorce
                                au Québec, il n’est pas surprenant que les cours
                                soient encombrées par des poursuites en divorce,
                                en séparation de corps et, inévitablement, en
                                demande de garde d’enfants. Ces derniers sont
                                les toujours les innocents de l’histoire qui
                                écopent lorsque les relations entre les parents
                                se détériorent. D’ailleurs, la loi en fait sa
                                priorité de protéger leurs intérêts avant ceux
                                des parents.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-droit-immobilier-vente-achat-maison/">
                            <h2>
                                Avocat en droit immobilier : l’importance des
                                services juridiques lors de l’achat d’une maison
                            </h2>
                            <span>
                                {renderDate('2019-10-18T09:41:31+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-droit-immobilier-services-consultation-achat-vente-maison.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                C’est l’objectif de bien des Québécois de
                                pouvoir un jour se proclamer propriétaire de
                                leur propre maison. Il s’agit d’un point
                                tournant de la vie de tous et ce n’est pas pour
                                rien qu’il comporte autant de formalités. En
                                effet une transaction immobilière impliquant une
                                maison est certaine d’engendrer quelques
                                complications, délais et procédures.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/pension-alimentaire-enfants-services-avocats/">
                            <h2>
                                Qu’est-ce que la pension alimentaire pour
                                enfants? Services d’avocats en droit de la
                                famille!
                            </h2>
                            <span>
                                {renderDate('2019-10-18T09:34:57+00:00')}
                            </span>
                            <StaticImage
                                src="../images/pension-alimentaire-calcul-divorce-separation-sercices-avocat.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                La séparation d’un couple ayant des enfants rime
                                depuis toujours avec les mots « pension
                                alimentaire ». Véritable secret de polichinelle
                                de la séparation, l’un des conjoints devra
                                verser une pension alimentaire pour subvenir aux
                                besoins de ses enfants. Il sera parfois même
                                tenu d’en verser un à son ex-conjoint, mais
                                celle réservée à sa progéniture est suffisamment
                                intéressante pour être l’objet exclusif de ce
                                texte.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/services-avocats-accusations-criminelles-penales/">
                            <h2>
                                Les services d’un avocat pour des accusations
                                criminelles et pénales
                            </h2>
                            <span>
                                {renderDate('2019-10-14T14:10:52+00:00')}
                            </span>
                            <StaticImage
                                src="../images/services-avocat-accusations-criminelles-conseils-prix-quebec.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Qu’elles soient fondées ou non, les accusations
                                criminelles portées contre vous auront des
                                conséquences graves si elles ne sont pas
                                traitées adéquatement par des professionnels du
                                droit. Bien que les innocents soient rarement
                                accusés et condamnés à tort dans un système
                                judiciaire comme celui du Canada, l’histoire
                                démontre qu’un tel scénario ne relève pas
                                complètement de la fiction!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/location-logement-droits-locataire-avocat/">
                            <h2>
                                Location d’un appartement : les droits du
                                locataire et les motifs de résiliation de bail
                            </h2>
                            <span>
                                {renderDate('2019-10-14T14:06:18+00:00')}
                            </span>
                            <StaticImage
                                src="../images/location-logement-appartement-resiliation-bail-avocat.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                La vie en appartement est un passage par lequel
                                la plupart des gens passent. Que vous ayez été
                                obligé d’aller étudier à l’extérieur pour vos
                                études, que vous ayez quitté le nid familial
                                pour jouir d’une pleine liberté ou que vous
                                n’ayez tout simplement pas envie de devenir
                                propriétaire, la location est une aventure qui
                                n’est pas toujours rose. En effet, comme vous
                                demeurez dans la propriété d’une autre personne
                                (votre locateur), vous devez entretenir une
                                saine relation avec celui-ci pour éviter les
                                problèmes.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/services-avocats-mediation-arbitrage-quebec/">
                            <h2>
                                Médiation, négociation et arbitrage : comment
                                éviter de se retrouver devant les tribunaux!
                            </h2>
                            <span>
                                {renderDate('2019-10-14T09:42:37+00:00')}
                            </span>
                            <StaticImage
                                src="../images/mediation-objectif-cout-rapprocher-parties-avocat-mediateur.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Le système de justice québécois étant ce qu’il
                                est, il est extrêmement difficile et pénible
                                d’obtenir un jugement favorable dans délais
                                raisonnables. Même lorsqu’un juge tranche en
                                votre faveur, s’il le fait plusieurs mois après
                                les faits, il se peut très bien que la situation
                                soit devenue impertinente. Bien que des
                                dommages-intérêts soient envisageables, il faut
                                toujours considérer les frais extrajudiciaires
                                rattachés aux services de la cour ainsi que les
                                honoraires d’avocats.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/marriage-divorce-famille-services-avocat/">
                            <h2>
                                Mariage et divorce : les services de l’avocat en
                                droit de la famille
                            </h2>
                            <span>
                                {renderDate('2019-10-14T09:35:44+00:00')}
                            </span>
                            <StaticImage
                                src="../images/mariage-divorce-services-avocat-droit-famille-quebec.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                S’il est vrai que le mariage a longtemps eu la
                                cote au Québec pour des raisons culturelles et
                                religieuses, la réalité d’aujourd’hui est que
                                beaucoup de Québécois choisissent de vivre en
                                Union de fait. Cela ne signifie toutefois pas
                                que le mariage est devenu une institution
                                obsolète, au contraire! Encore des milliers de
                                Québécois décident de consacrer ultimement leur
                                amour chaque année par les liens sacrés de ce
                                type d’union.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/droit-travail-recours-salarie-avocat/">
                            <h2>
                                Les droits et recours du salarié dans son
                                environnement de travail
                            </h2>
                            <span>
                                {renderDate('2019-10-14T09:24:07+00:00')}
                            </span>
                            <StaticImage
                                src="../images/droit-recours-salarie-environnement-travail-avocat.jpg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Subir un préjudice au travail, qu’il soit
                                physique, matériel ou moral, laisse des traces
                                indélébiles sur les victimes. Quelle que soit la
                                nature du préjudice subi, lorsqu’il résulte de
                                la faute d’un employeur, il y a lieu d’être
                                compensé pour un tel manquement de diligence. Ce
                                principe est d’ailleurs reconnu par le droit
                                québécois et appuyé par diverses lois du
                                travail, peu importe le milieu dans lequel vous
                                œuvrez.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/droits-protections-conjoints-fait-avocat-quebec/">
                            <h2>
                                Quels sont les droits et les protections des
                                conjoints de fait au Québec?
                            </h2>
                            <span>
                                {renderDate('2019-10-14T09:18:56+00:00')}
                            </span>
                            <StaticImage
                                src="../images/protections-droits-recours-conjoints-fait-quebec-services-avocat.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Au Québec, ce sont de plus en plus de couples
                                qui choisissent de vivre leur vie en union de
                                fait et de ne jamais officialiser leur union par
                                les liens du mariage. Cette décision est des
                                plus personnelles et en concerne que les
                                principaux intéressés, cela va de soi.
                                Cependant, comme la loi traite les couples
                                mariés et les conjoints de fait avec des mesures
                                différentes, il est essentiel pour ces derniers
                                de connaître les dispositions que la loi prévoit
                                pour eux, mais surtout les droits qu’elle ne
                                prévoit pas.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/vice-cache-vente-maison-avocat-immobilier/">
                            <h2>
                                Que faire si vous découvrez un vice caché lors
                                de l’achat ou la vente d’une propriété?
                            </h2>
                            <span>
                                {renderDate('2019-10-14T09:06:53+00:00')}
                            </span>
                            <StaticImage
                                src="../images/vice-cache-etapes-suivre-recours-vendeur-avocat-quebec.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Les transactions immobilières sont des
                                opérations très délicates qui mettent en jeu
                                d’importantes sommes d’argent. Le transfert
                                d’une propriété n’est pas traité comme une
                                procédure banale, car elle peut mettre toutes
                                les parties dans de beaux draps lorsqu’elle
                                tourne au vinaigre. C’est notamment le cas des
                                ventes de maisons ou d’immeubles qui impliquent
                                la découverte d’un vice caché!{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/admissibilite-aide-juridique-gouvernementale/">
                            <h2>
                                Êtes-vous admissibles à l’aide juridique
                                gouvernementale?
                            </h2>
                            <span>
                                {renderDate('2019-10-04T09:39:54+00:00')}
                            </span>
                            <StaticImage
                                src="../images/admissibilite-aide-juridique-gouvernementale-gratuite-contributive.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Conscient des problèmes criants d’accès à la
                                justice et du prix toujours élevé des services
                                juridiques, le gouvernement du Québec met en
                                place un programme d’aide juridique visant à
                                venir en aide aux citoyens dont les moyens
                                financiers sont limités. Ce service public est
                                d’abord offert gratuitement aux gens percevant
                                un salaire annuel en deçà du seuil minimal prévu
                                par la loi pour ensuite être modifié en 1997
                                pour inclure un plus large éventail de gens dans
                                le besoin avec l’ajout d’un volet contributif.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-affaires-commercial-corporatif/">
                            <h2>
                                Trouvez des avocats en droit des affaires
                                commercial et corporatif dans votre région!
                            </h2>
                            <span>
                                {renderDate('2019-05-27T15:37:34+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-droit-affaires-commercial-corporatif-quebec.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Trouvez le meilleur avocat pour vous pour vous
                                représenter/conseiller pour votre cause en droit
                                corporatif: Le monde des affaires commercial et
                                celui du droit sont intrinsèquement liés, et ce,
                                depuis déjà plusieurs années. L’un ne saurait
                                survire sans l’autre et c’est pourquoi les
                                dirigeants d’entreprise ont de plus en plus
                                besoin de conseils juridiques pour bien diriger
                                leur entreprise vers leurs objectifs. Loin de se
                                limiter à la plaidoirie, le champ d’aptitudes
                                des avocats d’affaires s’étend également aux
                                domaines propres à la gestion de compagnie, tels
                                que la rédaction de contrats, les disputes
                                d’actionnaires et la restructuration
                                d’entreprise.
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-redaction-convention-actionnaires/">
                            <h2>
                                Avocat pour la rédaction ou l’analyse de
                                convention entre actionnaires
                            </h2>
                            <span>
                                {renderDate('2019-05-24T06:49:38+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-redaction-analyse-convention-actionnaires-quebec.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                Une société par actions est une entité pour le
                                moins complexe. De nombreuses structures
                                administratives doivent être mises en place pour
                                assurer son fonctionnement, ce qui implique un
                                important lot de services juridiques. En effet,
                                comme une compagnie est la propriété de ses
                                actionnaires et que ceux-ci peuvent être très
                                nombreux, il faut prévoir les règles de jeu
                                avant que l’aventure ne commence.{' '}
                            </p>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/avocat-incorporation-entreprise-quebec/">
                            <h2>
                                Quel est le tarif d’un avocat pour
                                l’incorporation d’une entreprise au Québec en
                                2021
                            </h2>
                            <span>
                                {renderDate('2019-05-20T10:51:50+00:00')}
                            </span>
                            <StaticImage
                                src="../images/avocat-droit-affaires-incorporation-entreprise-prix.jpeg"
                                alt=""
                                layout="fixed"
                                height={150}
                            />
                            <p>
                                L’incorporation d’une entreprise n’est pas un
                                processus particulièrement complexe. Cependant,
                                incorporer une entreprise de façon optimale en
                                mettant en place une structure solide est un
                                tout autre jeu qui nécessite l’intervention
                                d’experts. Cette expertise, vous la trouverez
                                auprès d’un avocat des affaires qui œuvre dans
                                la mise en place de structures corporatives et
                                qui s’y connait dans le monde du droit
                                commercial.{' '}
                            </p>
                        </Link>
                    </Grid>
                </Grid>
            </Main>
        </Layout>
    )
}

export default Blog
